/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Inject,
  Injectable,
  Renderer2,
  RendererFactory2,
  inject,
  signal,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { HelperService } from './helperService.service';
import { WindowRefService } from './window-ref-service.service';

const DARKMODE_KEY = 'darkMode';
const THEME_KEY = 'preferredTheme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer2: Renderer2;
  private style: HTMLStyleElement | undefined;
  private themeCSSID = 'theme';
  private currentTheme: ThemeObject | undefined;
  renderFactory = inject(RendererFactory2);
  windowRefService = inject(WindowRefService);
  helperService = inject(HelperService);
  darkMode$ = signal(false);

  @Inject(DOCUMENT) private document = inject(DOCUMENT);

  constructor() {
    this.renderer2 = this.renderFactory.createRenderer(null, null);
  }

  initializeTheme(configTheme: string) {
    const isDarkMode = this.isDarkMode();
    const preferredTheme =
      this.helperService.getStorage('local', THEME_KEY) ?? configTheme;
    const theme =
      LIGHT_THEMES.find((t: any) => t.value === preferredTheme) ??
      LIGHT_THEMES[0];
    const isDefault = !theme;
    this.setTheme(theme, isDarkMode, isDefault);
    // this.setDarkMode(isDarkMode);
  }

  setTheme(theme: ThemeObject, darkMode?: boolean, defaultTheme = false) {
    if (this.currentTheme) {
      this.removeExistingThemeStyle(this.themeCSSID, this.currentTheme);
    }

    this.style = this.renderer2.createElement('style') as HTMLStyleElement;
    this.style.id = this.themeCSSID;
    this.document.head.appendChild(this.style);
    const body = this.document.body;

    if (darkMode) {
      body.classList.add(`${theme.value}-dark`);
    } else {
      body.classList.add(theme.value);
    }
    this.currentTheme = theme;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !defaultTheme &&
      this.helperService.setStorage('local', THEME_KEY, theme.value);
  }

  removeExistingThemeStyle(id: string, theme: ThemeObject) {
    const existingStyle = this.document.getElementById(id);
    if (existingStyle) {
      this.document.head.removeChild(existingStyle);
      const body = this.document.body;
      body.classList.remove(theme.value, `${theme.value}-dark`);
    }
  }

  setDarkMode(darkMode: boolean) {
    this.darkMode$.set(darkMode);
    const body = this.document.body;
    if (this.currentTheme) {
      if (darkMode) {
        body.classList.remove(this.currentTheme.value);
        body.classList.add(`${this.currentTheme.value}-dark`);
      } else {
        body.classList.remove(`${this.currentTheme.value}-dark`);
        body.classList.add(this.currentTheme.value);
      }
    }
    this.helperService.setStorage('local', DARKMODE_KEY, darkMode);
  }

  isDarkMode(): boolean {
    return false;
    // const darkmodeValue = this.helperService.getStorage('local', DARKMODE_KEY);
    // const isDarkMode = this.parseJson(darkmodeValue);
    // return isDarkMode ?? this.windowRefService.nativeWindow?.matchMedia("(prefers-color-scheme:dark)").matches ?? false;
  }

  parseJson(data: any) {
    try {
      return JSON.parse(data);
    } catch (error: any) {
      return null;
    }
  }
}

export const LIGHT_THEMES: ThemeObject[] = [
  {
    value: 'brand-theme',
    label: 'Red-Grey',
    accent: '#4a90e2',
    primary: '#ffffff',
  },
];

export interface ThemeObject {
  value: string;
  label: string;
  accent: string;
  primary: string;
}
